import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IIbptImport } from '../interface/ibpt-import';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IbptImportService {

  private _isFromMatrix = false;
  private _baseUrl: string = `${environment.mkgoURL}/api/v1/ibpts`;
  
  constructor(
    private _http: HttpClient,
    private _dataService: DataService
  ) { }

  async saveIbtp(items: IIbptImport[]) {
    const url = this._baseUrl;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this._http.post(url, JSON.stringify({ ibpts: items }), options));
    console.log(response);
  }

  async getIbptData(code: number | string): Promise<{
    nationalFederal: number;
    state: number;
    municipal: number;
    key: string;
  }> {
    if (code === null || code === undefined || code === "") {
      return;
    }
    
    const url = `${this._baseUrl}/code/${code}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this._http.get(url, options));

    return (response as any).ibpt;
  }
}
